import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import styled from "styled-components"
import logo from "../images/evolve-organix-logo.svg"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Premium CBD" />
      <Wrapper>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        <div>
          <ComingSoon>Launching Early 2021</ComingSoon>
        </div>

      </Wrapper>
    </Layout>
  )
}

export default IndexPage

const Wrapper = styled.div `
  display: flex;
  justify-content:center;
  align-items:center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
`

const LogoContainer = styled.div `
  padding: 0 64px;
  position: relative;
  width: 100%;
  max-width: 64rem;
  height: auto;
  margin-bottom: 40px;
`

const Logo = styled.img `
`
const ComingSoon = styled.p `
  font-size: 1.6rem;
  font-family: "Futura";
  color: #d9e7c5;
`
